<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">{{ purchasesOrder.code }}</h3>
    <div class="col-12">
      <dl class="row">
        <dt class="col-sm-4">{{ $t("PURCHASES_ORDERS.CREATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ $timeZoneDateFormat(purchasesOrder.created_at, "LLLL") }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("PURCHASES_ORDERS.EXPIRATION_TIME") }}</dt>
        <dd class="col-sm-8">
          {{ $timeZoneDateFormat(purchasesOrder.expiration_time, "LLLL") }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
        <dd class="col-sm-8">
          <locations :locations="purchasesOrder.allowedLocations" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd class="col-sm-8">
          <organization :organization="purchasesOrder.organization" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
        <dd class="col-sm-8">
          <purchases-order-status-badge
            :purchasesOrder="purchasesOrder"
            :advanced="true"
          />
        </dd>
      </dl>

      <dl class="row" v-if="purchasesOrder.purchasesInvoice">
        <dt class="col-sm-4">{{ $t("COMMON.PURCHASES_INVOICE") }}</dt>
        <dd class="col-sm-8">
          <object-link :object="purchasesOrder.purchasesInvoice" />
          <purchases-invoice-status-badge
            :purchasesInvoice="purchasesOrder.purchasesInvoice"
          />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.ISSUER") }}</dt>
        <dd class="col-sm-8">
          <object-link :object="purchasesOrder.issuer" />
        </dd>
      </dl>

      <dl class="row" v-if="purchasesOrder.destinationWarehouse">
        <dt class="col-sm-4">{{ $t("COMMON.WAREHOUSE") }}</dt>
        <dd class="col-sm-8">
          <object-link :object="purchasesOrder.destinationWarehouse" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.TOTAL") }}</dt>
        <dd class="col-sm-8" v-if="purchasesOrder.pricing">
          <span> {{ $formatCurrency(purchasesOrder.pricing.total) }} </span>
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4" v-if="purchasesOrder.excerpt">
          {{ $t("COMMON.NOTE") }}
        </dt>
        <dd class="col-sm-8" v-if="purchasesOrder.excerpt">
          <div v-html="purchasesOrder.excerpt"></div>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import PurchasesInvoiceStatusBadge from "../../PurchasesInvoiceManagement/partials/PurchasesInvoiceStatusBadge.vue";
import PurchasesOrderStatusBadge from "./PurchasesOrderStatusBadge.vue";

export default {
  name: "purchasesOrder-view-global",

  components: {
    PurchasesOrderStatusBadge,
    PurchasesInvoiceStatusBadge,
  },

  props: ["purchasesOrder"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    purchasesOrder(purchasesOrder) {},
  },
};
</script>
