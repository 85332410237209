<template>
  <modal :show="showModal" modal-classes="modal-secondary" size="lg">
    <div class="text-center mb-5">
      <h2>{{ $t("PURCHASES_ORDERS.ADD_DELIVERY") }}</h2>
    </div>
    <form class="" @submit.prevent="">
      <div
        class="row delivery-item"
        v-for="item in purchasesOrder.deliveryItemsState.remainingItems"
        :key="item.id"
      >
        <div class="col-8 delivery-item-name">
          <span class="code text-bold h3">{{ item.code }}</span>
          <span class="name">{{ item.name }}</span>
          <div class="except text-muted" v-html="item.excerpt"></div>
        </div>
        <div class="col-4">
          <base-input
            :label="`${$t('COMMON.QUANTITY')} (*)`"
            :placeholder="$t('COMMON.QUANTITY')"
            v-model="deliveryItemsModel[item.id].quantity"
            type="number"
            step="1"
            input-classes="form-control-alternative"
          >
          </base-input>
          <validation-error :errors="apiValidationErrors.quantity" />
        </div>
      </div>
    </form>

    <template slot="footer">
      <button
        type="submit"
        class="btn btn-primary"
        @click="handleSubmit"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("PURCHASES_ORDERS.ADD_DELIVERY") }}
      </button>

      <button
        type="button"
        class="btn btn-link ml-auto"
        @click="closePurchasesOrderDeliveryForm"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import {
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ValidationError from "@/components/ValidationError.vue";

export default {
  name: "purchasesOrder-view-details-purchasesOrder-item-form",

  components: {
    ValidationError,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [formMixin, requestErrorMixin],

  props: ["purchasesOrder", "showModal"],

  data() {
    return {
      deliveryItemsModel: this.getDeliveryItemsModel(),
      loading: false,
    };
  },

  computed: {},

  methods: {
    getDeliveryItemsModel() {
      const deliveryItemsData = {};
      if (this.purchasesOrder.deliveryItemsState.remainingItems) {
        for (const item of Object.values(
          this.purchasesOrder.deliveryItemsState.remainingItems
        )) {
          deliveryItemsData[item.id] = {
            id: item.id,
            quantity: item.quantity,
          };
        }
      }
      return deliveryItemsData;
    },

    closePurchasesOrderDeliveryForm() {
      this.$emit("closePurchasesOrderDeliveryForm", true);
    },

    async handleSubmit() {
      this.loading = true;
      const deliveryItemsModelData = cloneDeep(this.deliveryItemsModel);
      try {
        const delivery = await this.$store.dispatch(
          "purchasesOrders/generateDelivery",
          {
            id: this.purchasesOrder.id,
            items: deliveryItemsModelData,
          }
        );
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("PURCHASES_ORDERS.PURCHASES_ORDER_DELIVERY_ADDED"),
        });
        this.$router.push(this.$objectViewRoute(delivery));
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        await this.showRequestError(error);
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },

  mounted() {},

  watch: {
    showModal() {
      this.deliveryItemsModel = this.getDeliveryItemsModel();
    },
    purchasesOrder() {
      this.deliveryItemsModel = this.getDeliveryItemsModel();
    },
  },
};
</script>
<style scoped>
.delivery-item .delivery-item-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
